import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Helmet from "react-helmet"
import moment from 'moment'
import Shop from "../components/shop"
import { ShopNameTag, ShopName, ShopNameSelected, ImageFilename} from '../utils/common'

const AllCampaign = ({ data, location, pageContext: { shop_id } }) => {
	const title = `${ShopNameTag(shop_id)}キャンペーン情報`
	let description = `${ShopNameTag(shop_id)}現在開催しているキャンペーンと今後開催予定のキャンペーン！`

	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment().format('YYYY年M月D日')

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		}]
	};

	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
			</ul>
		</nav>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>

				 <div className="content">
					<h3 className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{ShopNameTag(shop_id)}キャンペーン情報<small> ({update}更新)</small></h3>
					<Shop shopId={shop_id}/>
				</div>
			</>
		<Seo title={title} description={description} canonical={location.pathname} />
  </Layout>
	)
}

export default AllCampaign
